<template>
  <div class="auth">
    <Header :title="'认证'" :back="true"/>
    <div class="box-items">
      <div v-if="type=='re'" class="utitle">为确保您的资金账户安全，投资前需完成实名认证！</div>
      <el-input
        class="form-items"
        placeholder="请输入真实姓名"
        v-model="name"
        @focus="tips=''"
        prefix-icon="el-icon-user"
        clearable>
      </el-input>
      <el-input
        class="form-items"
        @focus="tips=''"
        placeholder="请输入身份证号码"
        v-model="idcard"
        prefix-icon="el-icon-postcard"
        clearable>
      </el-input>
      <div v-if="tips" class="error-tips"><i class="el-icon-error"></i> {{tips}}</div>
      <div  class="utitle theme-color" style="margin-top:6px" v-if="type=='re'" @click="jumpStep">暂时跳过</div>
      <div v-else class="checkbox-box"><el-checkbox style="margin-right:6px" v-model="checked"></el-checkbox>我已阅读并同意《用户投资账户协议》</div>
      <div class="form-items"><button class="wid-btn" style="width:96%" @click="handleAuth">极速认证开户</button></div>
      <div class="checkbox-box theme-color" style="text-align:center;margin-top:20px">无中国大陆身份证请联系客服</div>
    </div>
   <el-dialog
      :visible.sync="drawer"
      width="85%"
      :show-close="false"
      custom-class="pwd-box"
      >
      <div class="draw-box">
        <div class="close-icon" @click="drawer=false"><i class="el-icon-circle-close theme-color"></i></div>
        <div class="draw-title">请设置交易密码</div>
        <div>
          <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password1"
            @complete="onPasswordInputComplete1"/>
        </div>
        <div>
          <div class="draw-title">请再次确认交易密码</div>
          <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password2"
            @complete="onPasswordInputComplete2"/>
        </div>
        <div v-if="tips2" class="error-tips" style="margin-left:10%"><i class="el-icon-error"></i> {{tips2}}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Password from '@/components/Password/Index'
export default {
  components: {
    Header,
    Password
  },
  data(){
    return{
      type:this.$route.query.type,
      drawer:false,
      checked:true,
      name:'',
      idcard:'',
      tips:'',
      tips2:"",
      password1:'',
      password2:''
    }
  },
  // mounted(){
  //   this.judgeInput()
  // },
  // unmounted(){
  //   window.removeEventListener("resize");
  // },
  methods:{
    // scrollIntoView(){
    //   window.addEventListener("resize",()=>{
    //     document.activeElement.scrollIntoView(true)
    //   })
    // },
//   judgeInput() {
//   if (this.isIOS()) {
//     window.addEventListener('focusin', function () {
//       if (
//         document.activeElement.tagName === 'INPUT' ||
//         document.activeElement.tagName === 'TEXTAREA'
//       ) {
//         setTimeout(function () {
//           document.documentElement.scrollTop = document.body.scrollHeight;
//         }, 0);
 
//       }
//     });
//   } else {
//     window.addEventListener('resize', function () {
//       console.log(2+ document.activeElement.tagName);
//       if (
//         document.activeElement.tagName === 'INPUT' ||
//         document.activeElement.tagName === 'TEXTAREA'
//       ) {
//         setTimeout(function () {
//           document.activeElement.scrollIntoView();
//         }, 0);
//       }
//     });
//   }
// },
    // sIOS() {
    //   const u = navigator.userAgent;
    //   return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // },
    handleAuth(){
      if (this.name === ''||this.idcard === '') {
        this.tips = "姓名和身份证号码不能为空"
        return false
      }else if(this.checkCard(this.idcard)==false){
        this.tips = "身份证号码格式不正确"
        return false
      }
      let params={
        name:this.name,
        card:this.idcard,
        type:'idcard'
      }
      this.$store
        .dispatch('auth/validationsAuth',params)
        .then((res) => {
          if(res.code==0){
             this.drawer = true
          }
        })
    },
    onPasswordInputComplete1(){
      // console.log(pwd)
    },
    onPasswordInputComplete2(){
      if(this.password1!=this.password2){
        this.tips2 = "两次密码不一致"
        return false
      }
      let params={
        password:this.password2,
        type:'tradepassword'
      }
      this.$store
        .dispatch('auth/validationsAuth',params)
        .then((res) => {
          if(res.code==0){
            this.drawer = true
            this.$router.push({name:'authentication',query:{refer:"auth"}})
          }
        })
    },
    jumpStep(){
      this.$router.push({name:'home'})
    }
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .box-items{
    margin-top:2.5%
  }
  .form-items{
    margin: 1rem auto 0px;
  }
  .checkbox-box{
    font-size: .8rem;
    color: #464646;
    margin-top:3px;
   
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
    margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.utitle{
  color: #464646;
  // line-height: 2;
  font-size: .9rem;
}
</style>