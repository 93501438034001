var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c("Header", { attrs: { title: "认证", back: true } }),
      _c(
        "div",
        { staticClass: "box-items" },
        [
          _vm.type == "re"
            ? _c("div", { staticClass: "utitle" }, [
                _vm._v("为确保您的资金账户安全，投资前需完成实名认证！"),
              ])
            : _vm._e(),
          _c("el-input", {
            staticClass: "form-items",
            attrs: {
              placeholder: "请输入真实姓名",
              "prefix-icon": "el-icon-user",
              clearable: "",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("el-input", {
            staticClass: "form-items",
            attrs: {
              placeholder: "请输入身份证号码",
              "prefix-icon": "el-icon-postcard",
              clearable: "",
            },
            on: {
              focus: function ($event) {
                _vm.tips = ""
              },
            },
            model: {
              value: _vm.idcard,
              callback: function ($$v) {
                _vm.idcard = $$v
              },
              expression: "idcard",
            },
          }),
          _vm.tips
            ? _c("div", { staticClass: "error-tips" }, [
                _c("i", { staticClass: "el-icon-error" }),
                _vm._v(" " + _vm._s(_vm.tips)),
              ])
            : _vm._e(),
          _vm.type == "re"
            ? _c(
                "div",
                {
                  staticClass: "utitle theme-color",
                  staticStyle: { "margin-top": "6px" },
                  on: { click: _vm.jumpStep },
                },
                [_vm._v("暂时跳过")]
              )
            : _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c("el-checkbox", {
                    staticStyle: { "margin-right": "6px" },
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                  _vm._v("我已阅读并同意《用户投资账户协议》"),
                ],
                1
              ),
          _c("div", { staticClass: "form-items" }, [
            _c(
              "button",
              {
                staticClass: "wid-btn",
                staticStyle: { width: "96%" },
                on: { click: _vm.handleAuth },
              },
              [_vm._v("极速认证开户")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "checkbox-box theme-color",
              staticStyle: { "text-align": "center", "margin-top": "20px" },
            },
            [_vm._v("无中国大陆身份证请联系客服")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.drawer,
            width: "85%",
            "show-close": false,
            "custom-class": "pwd-box",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "draw-box" }, [
            _c(
              "div",
              {
                staticClass: "close-icon",
                on: {
                  click: function ($event) {
                    _vm.drawer = false
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-circle-close theme-color" })]
            ),
            _c("div", { staticClass: "draw-title" }, [
              _vm._v("请设置交易密码"),
            ]),
            _c(
              "div",
              [
                _c("Password", {
                  ref: "passwordInputRef",
                  attrs: { mask: "", pwdd: _vm.password1 },
                  on: {
                    "update:pwdd": function ($event) {
                      _vm.password1 = $event
                    },
                    complete: _vm.onPasswordInputComplete1,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "draw-title" }, [
                  _vm._v("请再次确认交易密码"),
                ]),
                _c("Password", {
                  ref: "passwordInputRef",
                  attrs: { mask: "", pwdd: _vm.password2 },
                  on: {
                    "update:pwdd": function ($event) {
                      _vm.password2 = $event
                    },
                    complete: _vm.onPasswordInputComplete2,
                  },
                }),
              ],
              1
            ),
            _vm.tips2
              ? _c(
                  "div",
                  {
                    staticClass: "error-tips",
                    staticStyle: { "margin-left": "10%" },
                  },
                  [
                    _c("i", { staticClass: "el-icon-error" }),
                    _vm._v(" " + _vm._s(_vm.tips2)),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }